import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions, FormlyTemplateOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { isDate } from 'date-fns';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { RequestCache } from '@logic-suite/shared/cache';
import { objClone } from '@logic-suite/shared/utils/objClone';
import { objToString, sentenceCase, titleCase } from '@logic-suite/shared/utils/stringUtils';

import { CustomerService } from '../access';
import { AssetNode, AssetTreeService } from '../nav/asset-tree';
import { Setting, SettingConfig, SettingItem, SettingType } from './setting.model';

@Injectable({ providedIn: 'root' })
export class SettingsService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private settings$ = new BehaviorSubject<SettingConfig>({});

  constructor(
    private http: HttpClient,
    private customer: CustomerService,
    private cache: RequestCache,
    private tree: AssetTreeService,
    private translate: TranslateService,
  ) {
    // Load all settings once each time a new customer is selected.
    this.subscriptions.push(
      this.customer.selectedCustomer$
        .pipe(
          filter((customer) => !!customer && !!customer.customerID),
          switchMap((customerObj) => {
            if (customerObj != null) {
              return this.http.get<SettingConfig>('/api/shared/WidgetSettings', {
                params: {
                  customerID: '' + customerObj.customerID,
                },
              });
            }
            return of({});
          }),
        )
        .subscribe((settings) => this.setSettings(settings)),
    );
  }

  private setSettings(settings: SettingConfig) {
    this.settings$.next(settings);
  }

  /**
   * Fetch settings.
   * If `widgetName` is provided, limit the returned object to only this widget.
   * If `node` is provided, return only the overrides for this position.
   *
   * @param widgetName The widget to fetch settings for
   * @param node The position to fetch settings for
   */
  getSettings(widgetName?: string, node?: AssetNode | null): Observable<Setting[]> {
    return this.settings$.pipe(
      // Do not resolve before we have loaded settings
      filter((settings) => !!settings),

      // Filter out anything but the requested widgetName if requested
      map((settings) => {
        if (!widgetName || !settings) {
          return settings;
        }

        const amputated = widgetName.substring(0, widgetName.lastIndexOf('-'));
        const child = widgetName.substring(widgetName.lastIndexOf('-') + 1);
        if (widgetName in settings) {
          // Widget name is in settings directly. Just return it.
          return { [widgetName]: settings[widgetName] };
        } else if (amputated in settings) {
          // Widget name is a child of settings. Return the child.
          return { [widgetName]: settings[amputated]['___' + titleCase(child)] };
        }
        return {}; // Nothing. Giving up.
      }),

      // Wait for asset tree
      switchMap((settings) => (node ? this.tree.nodeSelection$.pipe(map(() => settings)) : of(settings))),

      // Get only the overrides if requested
      map((settings) => {
        if (!node) {
          return settings;
        }

        // Create a clone as not to destruct original information
        const copy: SettingConfig = objClone(settings);
        // Manipulate array setting appropriate overrides as value
        Object.entries(copy).forEach(([widget, settingItem]) => {
          Object.entries(settingItem || {}).forEach(([property, val]) => {
            const override = val.entryTypeOverride?.find(
              (o) => o.entryType === node.type && `${o.entryId}` === `${node.id}`,
            );
            if (override) {
              copy[widget][property].value = override.value;
              copy[widget][property].isOverride = true;
            }
          });
        });
        return copy;
      }),
      map((settings) =>
        Object.entries(settings || []).map(([widget, val]) => {
          // Map from SettingConfig to a Setting array
          return {
            widget,
            name: widget,
            settings: val,
            values: !val
              ? {}
              : Object.entries(val)
                  .filter(([k, v]) => v != null && k.indexOf('___') != 0)
                  .reduce(
                    (a, [k, v]: [string, any]) => {
                      a[k] = v == null || typeof v !== 'object' ? v : v['value'];
                      return a;
                    },
                    {} as Record<string, unknown>,
                  ),
          } as Setting;
        }),
      ),
    );
  }

  /**
   * Save a setting. If `node` is provided, the setting will be saved as an override for this
   * particular position.
   *
   * @param setting The setting to save. If setting value is null, reset overrides.
   * @param node The position to save it for
   */
  save(setting: { [key: string]: SettingType | null }, node?: AssetNode) {
    const allSettings = objClone(this.settings$.value) || {};
    Object.entries(setting).forEach(([widgetName, config]) => {
      if (config) {
        // If no config is provided, this is a reset and we can skip this logic.
        let subProperty: string | null = null;
        let widget: string = widgetName;
        Object.entries(config).forEach(([prop, value]) => {
          let widgetSetting = allSettings[widget];
          let isChildProperty = false;
          if (!(widget in allSettings)) {
            // The widget is not directly specified in allSettings. We are probably in a child-config
            // This will only be done once per widget
            const child = widget.substring(widget.lastIndexOf('-') + 1);
            widget = widget.substring(0, widget.lastIndexOf('-'));
            subProperty = '___' + titleCase(child);
          }
          if (prop in allSettings[widget]) {
            // Parent level config is to be saved
            widgetSetting = allSettings[widget];
          } else if (subProperty && prop in allSettings[widget][subProperty]) {
            // Child level config is to be saved
            isChildProperty = true;
            widgetSetting = allSettings[widget][subProperty];
          }
          if (typeof widgetSetting[prop] == 'object' && 'value' in widgetSetting[prop]) {
            // Object is given, so object must be written
            widgetSetting[prop].value = value;
            // Also rewrite given setting
            if (subProperty && isChildProperty) {
              setting[widget] = Object.assign({}, allSettings[widget], setting[widget], {
                [subProperty]: { [prop]: { value } },
              });
              delete (setting[widget] as { [key: string]: any })[prop];
            } else {
              !setting[widget]
                ? ((setting[widget] as { [key: string]: any }) = { [prop]: { value } })
                : ((setting[widget] as { [key: string]: any })[prop] = { value });
            }
          } else {
            // Primitive is given, so primitive must be written
            widgetSetting[prop] = value;
          }
          if (widget !== widgetName) {
            delete (setting[widgetName] as { [key: string]: any })[prop];
            if (objToString(setting[widgetName]) === '{}') {
              delete setting[widgetName];
            }
          }
        });
      }
    });
    return this.customer.selectedCustomer$.pipe(
      filter((customer) => !!customer && !!customer.customerID),
      switchMap((customer) =>
        this.http.post('/api/shared/WidgetSettings', setting, {
          params: {
            customerID: '' + customer.customerID,
            ...(!!node && { entryType: node.type, ...(!!node.id && { entryID: node.id }) }),
          },
        }),
      ),
      tap((res) => {
        this.cache.invalidate('/api');
        const settings = objClone(this.settings$.value);
        Object.assign(settings, res);
        this.setSettings(settings);
      }),
      // switchMap(() => this.getSettings(setting., node))
    );
  }

  // /**
  //  * Revert overrides for this widget to global settings for this widget.
  //  *
  //  * @param widgetName The widget to reset
  //  * @param node The position to reset
  //  */
  // reset(widgetName: string, node: AssetNode) {
  //   return this.http.post('/api/shared/WidgetSettings/Reset', null, {
  //     params: {
  //       customerID: '' + this.customer.get().customerID,
  //       widgetName,
  //       entryType: node.type,
  //       ...(!!node.id && {entryID: node.id}),
  //     },
  //   });
  // }

  /**
   * Builds a formly object from the settings config.
   */
  buildForm(settings: Setting[], disabled = false): SettingItem[] {
    // Determine input type
    const getType = (value: any): string => {
      if (
        isDate(value) ||
        (typeof value === 'object' && 'attributes' in value && ['date', 'datepicker'].includes(value.attributes.type))
      ) {
        return 'datepicker';
      } else if (typeof value === 'object' && 'attributes' in value && 'type' in value.attributes) {
        if (['boolean'].includes(value.attributes.type)) {
          return 'checkbox';
        }
        return 'input';
      }
      switch (typeof value) {
        case 'object': {
          if (value == null) {
            // Default to textual input
            return 'input';
          }
          if ('type' in value) {
            // Type is specified in object. No need to guess.
            return value.type;
          }
          if ('options' in value) {
            // We have an options object. Decide if this is a multiple choice or not.
            return 'multiple' in value || value.options?.length > 6 ? 'select' : 'radio';
          }
          // None of the above. Decide type by inspecting value.
          return getType(value.value);
        }
        case 'boolean':
          return 'checkbox';
        case 'number':
        case 'string':
        default:
          return 'input';
      }
    };

    // Compile the template options object
    const getOptions = (key: string, options: any): FormlyTemplateOptions => {
      const def = {
        label: sentenceCase(key),
        // appearance: 'standard'
      };
      if (options != null && typeof options === 'object') {
        const { value, entryTypeOverride, isOverride, className, ...rest } = options;
        if ('attributes' in rest && rest.attributes.type === 'date') {
          rest.attributes.type = 'text';
        }
        return Object.assign({ disabled }, def, rest);
      }
      return def;
    };

    // Build formly configuration for a field
    const buildFormlyConfig = (key: string, value: any): FormlyFieldConfig => {
      if ('type' in value && value.type === 'fieldset') {
        return {
          key,
          className: value.className,
          template: `<div>${this.translate.instant(value.label || '')}</div>`,
        };
      }
      return {
        key,
        type: getType(value),
        templateOptions: getOptions(key, value),
        className: value.className || '',
        expressionProperties: {
          'templateOptions.label': () => this.translate.instant(sentenceCase(key)),
          ...('description' in value
            ? { 'templateOptions.description': () => this.translate.instant(value.description) }
            : {}),
        },
      } as FormlyFieldConfig;
    };

    // Build the formly form options
    return settings.reduce((acc, s) => {
      acc.push({
        header: sentenceCase(s.name),
        model: s.values as FormlyFormOptions,
        fields: !s.settings
          ? []
          : Object.entries(s.settings)
              // Remove all child settings
              .filter(([key, value]) => key.indexOf('___') != 0)
              // Build formly config
              .map(([key, value]) => buildFormlyConfig(key, value))
              // Wrap in fieldsets (if any)
              .reduce((acc: FormlyFieldConfig[], conf: FormlyFieldConfig, idx: number, arr: FormlyFieldConfig[]) => {
                if (conf.className && (conf.className?.indexOf('group') || -1) > -1) {
                  // This is a fieldset
                  const set = parseInt(
                    conf.className
                      .split(' ')
                      .find((c) => c.indexOf('group'))
                      ?.substring('group'.length) ?? '0',
                    10,
                  );
                  const groups = acc.filter((i) => 'fieldGroup' in i);
                  let group = [] as FormlyFieldConfig[];
                  if (groups.length < set) {
                    // This is a new fieldset
                    group = [] as FormlyFieldConfig[];
                    acc.push({ fieldGroup: group } as FormlyFieldConfig);
                  } else {
                    group = groups[set - 1].fieldGroup || ([] as FormlyFieldConfig[]);
                  }
                  group.push(conf as FormlyFieldConfig);
                } else {
                  acc.push(conf);
                }
                return acc;
              }, []),
      });
      return acc;
    }, [] as SettingItem[]);
  }

  /**
   * Cleanup!
   */
  ngOnDestroy() {
    this.subscriptions.filter((s) => !!s && !s.closed).forEach((s) => s.unsubscribe());
  }
}
