import {
  CellMouseOutEvent,
  CellMouseOverEvent,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import DOMPurify from 'dompurify';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LoaderService } from '@logic-suite/shared/components/loader';
import { Highcharts_i18n, LanguageService } from '@logic-suite/shared/i18n';
import { errorToString, objToString } from '@logic-suite/shared/utils';

import { AssetNode, AssetTreeService } from '../../nav/asset-tree';
import { SettingType, SettingsService } from '../../settings';
import { TooltipRow } from './tooltip-row';
import { WidgetGraphTooltipComponent } from './widget-graph-tooltip.component';
import { WidgetRepository } from './widget-repository.service';
import { WidgetComponent } from './widget.component';

type ChartState = {
  selected?: string;
  state: { id: string; previous: boolean; current: boolean }[];
};

export type GraphPredicate<T = any> = {
  x: number;
  y?: number;
  point: Highcharts.Point;
  row: T;
  seriesName: string;
  series: Highcharts.Series;
};

/**
 * This is what every widget component should extend from.
 *
 * Although we have a `WidgetComponent`, that component is a GUI wrapper
 * which should be present in a widget's template, but it is not a Widget in
 * itself. The `WidgetComponent` brings common GUI elements for all widgets,
 * but this class brings the common functionality to be inherited by all widgets.
 */
@Directive({
  host: {
    class: 'widget',
  },
})
export abstract class AbstractWidgetComponent<T = any> implements OnInit, AfterViewInit, OnDestroy {
  public readonly el = inject(ElementRef);
  protected readonly lang = inject(LanguageService);
  protected readonly translate = inject(TranslateService);
  protected readonly assetTree = inject(AssetTreeService);
  protected readonly route = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  protected readonly repository = inject(WidgetRepository);
  protected readonly settings = inject(SettingsService);
  protected readonly loader = inject(LoaderService);
  protected readonly destroyRef$ = inject(DestroyRef);
  private readonly viewContainerRef = inject(ViewContainerRef);

  self = this; // A reference for the component class for this widget
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputData: any; // Any input the individual widgets might require. This will come from the route definition
  currentLanguage = signal(this.lang.current);

  /** The query parameters used to persist state between view modes */
  get params() {
    return this.assetTree.calcQueryParams(this.assetTree.getSelectedNode());
  }

  /** Holds a reference to the inner `WidgetComponent`. Every component inheriting from this, must have this as a child element. */
  widgetComponent = viewChild.required<WidgetComponent>('widget');
  private resizeObserver = new ResizeObserver((entries) => {
    this.size$.next({ width: entries[0].contentRect.width, height: entries[0].contentRect.height });
  });
  private size$ = new BehaviorSubject({ width: 0, height: 0 });
  currentWidgetSize = toSignal(this.size$, { initialValue: { width: 0, height: 0 } });
  width = computed(() => this.currentWidgetSize().width);
  height = computed(() => this.currentWidgetSize().height);

  /** Holds all the subscriptions for this component, so we can easily cleanup on component destruction */
  subscriptions: Subscription[] = [];

  /** Common http load indicator. Used to turn spinners on/off */
  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading = toSignal(this.isLoading$);
  _reload = true;

  /** Common property to indicate whether data is available or not. This will display a message on top of graphs */
  hasData = signal(false);
  /** Common property to indicate if the component has an error. Errors will not display graphs, only the error */
  hasError = signal(false);

  /** Helper indicator for subscriptions to check if this component is still active before referencing UI objects */
  isMounted = signal(false);
  isDestroyed = signal(false);

  /** Common property to indicate whether datafetch was erroneous or not */
  error = signal<string | undefined>(undefined);
  errorDetails = signal<string | undefined>(undefined);

  // region Highcharts
  chart?: Highcharts.Options;
  Highcharts: typeof Highcharts = Highcharts;
  chartRef$ = new ReplaySubject<Highcharts.Chart>(1);
  chartObj = toSignal(
    this.chartRef$.pipe(
      filter((c) => !!c),
      map((c) => c as Highcharts.Chart),
    ),
    { initialValue: undefined },
  );
  chartCallback = (chart: Highcharts.Chart) => this.chartRef$.next(chart);
  chartState: ChartState = { selected: undefined, state: [] };
  hiddenSeries = signal<string[]>([]);
  hiddenSeries$ = toObservable(this.hiddenSeries);
  // endregion

  // region AG-Grid
  gridApi = signal<GridApi<T> | undefined>(undefined);
  gridOptions: GridOptions<T> = {
    gridId: this.widgetName,
    onGridReady: (event: GridReadyEvent) => this.gridApi.set(event.api),
    grandTotalRow: 'bottom',
    getRowId: (row: GetRowIdParams) => `${this.createHash(row.data)}`,
    onCellMouseOver: (params: CellMouseOverEvent<T>) => this.tableHover(params.data!),
    onCellMouseOut: (params: CellMouseOutEvent<T>) => this.reset(params.data!),
  };
  tableData = signal<any[]>([]);
  hiddenColumns = computed(
    () =>
      this.gridApi()
        ?.getColumnState()
        .filter((c) => c.hide)
        .map((c) => c.colId) || [],
  );
  hiddenColumns$ = toObservable(this.hiddenColumns);
  // endregion

  // region Settings
  config = signal<SettingType>({} as SettingType);
  config$ = toObservable(this.config).pipe(filter((config) => !!config && Object.keys(config).length > 0));
  private _oldConfig: SettingType = {};
  configChanges = computed(() => {
    const config = this.config();
    if (config == null || Object.keys(config).length === 0) return []; // No config, no changes
    const firstChange = Object.keys(this._oldConfig).length === 0; // Initial load should not trigger changes
    const changes = Object.keys(config).filter((key) => config[key] !== this._oldConfig[key]);
    this._oldConfig = { ...this.config() };
    return firstChange ? [] : changes;
  });
  configChanges$ = toObservable(this.configChanges).pipe(filter((changes) => changes.length > 0));
  configQueue: SettingType | null = null;
  // endregion

  backTitle = 'Settings';
  shiftDown = false;

  get node(): AssetNode {
    return this.assetTree.getSelectedNode();
  }
  routePath = '';

  languageCode: string;
  isTooltipShared: any = undefined;
  currentTabIndex = 0;

  /**
   * Sniff the view mode of this widget.
   * Widgets can be viewed as a part of a grid of widgets, or standalone
   * as a view of it's own.
   *
   * @returns `true` if the current view state is fullscreen. `false` if not.
   */
  fullscreen$ = new BehaviorSubject<boolean>(false);
  isFullscreen = toSignal<boolean>(this.fullscreen$); // This *will* come in late. Do not trust this on init

  static getName() {
    return 'No Name';
  }

  get fullWidgetName() {
    return this.widgetName;
  }

  @HostBinding('style.view-transition-name')
  @HostBinding('style.--widget-id')
  get widgetName() {
    return this.el.nativeElement.tagName.toLowerCase().trim();
  }
  @HostBinding('style.--chart-id')
  get chartID() {
    return this.widgetName + '-chart';
  }
  @HostBinding('style.--grid-id')
  get gridID() {
    return this.widgetName + '-grid';
  }
  @HostBinding('style.--header-id')
  get headerID() {
    return this.widgetName + '-header';
  }

  /**
   * Function to compare a datapoint to a row in the table
   *
   * Used to determine which row to highlight when hovering the graph, or which point in the graph
   * to show tooltip for when hovering the table.
   * Since each widget has its own property structure, this function should be overridden.
   * @param predicate
   * @returns true if table row matches point
   */
  graphPredicate = (predicate: GraphPredicate) => predicate.x == predicate.row.timestamp;

  /**
   *
   * @param el the element reference which should become a widget
   */
  constructor() {
    this.el.nativeElement.classList.add('draggable');
    this.languageCode = this.lang.current;
    this.destroyRef$.onDestroy(() => {
      this.isDestroyed.set(true);
      this.isMounted.set(false);
    });

    // React to common stuff
    effect(() => {
      // Refresh grid headers and cells when language changes
      if (this.currentLanguage()) {
        this.gridApi()?.refreshHeader();
        this.gridApi()?.refreshCells();
      }
    });
  }

  ngOnInit() {
    this.resizeObserver.observe(this.el.nativeElement);
    this.isMounted.set(true);

    // Load widget settings
    this.subscriptions.push(
      this.assetTree.nodeSelection$
        .pipe(
          takeUntilDestroyed(this.destroyRef$),
          switchMap((node) => this.settings.getSettings(this.widgetName, node)),
          filter((settings) => objToString(this.config()) !== objToString(settings[0]?.values)), // Only proceed if settings exist and are different from last load
        )
        .subscribe((settings) => this.config.set(settings[0]?.values)),
    );

    // Reset hasError each time widget starts loading
    this.subscriptions.push(
      this.isLoading$.subscribe((val) => {
        if (val) {
          this.hasError.set(false);
          this.error.set(undefined);
          this.errorDetails.set(undefined);
        }
      }),
    );

    // Handle language changes
    const opts = Highcharts.getOptions();
    Highcharts.setOptions(
      Object.assign(opts, { lang: Object.assign({}, opts.lang, Highcharts_i18n[this.lang.current]) }),
    );
    this.subscriptions.push(
      this.lang.onLangChange.pipe(filter((lng) => !!lng)).subscribe(async (lng: LangChangeEvent) => {
        Highcharts.setOptions(Object.assign(opts, { lang: Object.assign({}, opts.lang, Highcharts_i18n[lng.lang]) }));
        this.languageCode = lng.lang;
        const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
        this.onLangChange(lng, chart);

        if (this.gridApi() && !this.gridApi()?.isDestroyed()) {
          this.gridApi()!.refreshHeader();
          this.gridApi()!.refreshToolPanel();
          this.gridApi()!.refreshCells();
        }
      }),
    );

    // Set input data from route
    this.subscriptions.push(
      combineLatest([this.route.data, this.assetTree.nodeSelection$]).subscribe(([data, node]) => {
        if (!!data && !this.inputData) {
          this.inputData = data;
        }
        // Check if we should redirect to a synonym route
        if (data.synonym && data.redirectOn.includes(node.type)) {
          firstValueFrom(this.repository.route(data.synonym)).then((r) => {
            if (r)
              this.router.navigate(['..', r], {
                relativeTo: this.route,
                queryParamsHandling: 'preserve',
              });
          });
        }
      }),
    );

    // Toggle load spinner in fullscreen
    this.subscriptions.push(
      this.isLoading$.subscribe((val) => {
        if (this.isFullscreen()) {
          if (val) {
            this.loader.startLoad();
          } else {
            this.loader.forceStopAll();
          }
        }
      }),
    );

    // Detect the widget display state (widget/fullscreen)
    this.subscriptions.push(
      this.repository.route(this.widgetName).subscribe((route) => {
        this.routePath = route;
        const isFullscreen = this.router.url.indexOf(route) > -1;
        if (this.isFullscreen() !== isFullscreen) {
          this.fullscreen$.next(isFullscreen);
          this.el.nativeElement.classList.toggle('fullscreen', this.isFullscreen());
        }
      }),
    );
  }

  /**
   *
   */
  ngAfterViewInit() {
    this.onResize();
  }

  afterRender() {
    // setTimeout(() => this.onResize(), 500);
  }

  /**
   * Common cleanup. Requires that every component inheriting from this
   * places their subscriptions in this.`subscriptions` array.
   */
  ngOnDestroy() {
    this.gridApi()?.destroy();
    this.gridApi.set(undefined);
    this.resizeObserver.disconnect();
    this.isMounted.set(false);
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected abstract loadData(...args: any): Observable<any>;

  /**
   * Generic function to generate Highcharts tooltips
   */
  generateTooltip(rows: TooltipRow[], header?: string | TooltipRow, footer?: string | TooltipRow): string {
    // Create a tooltip component and render it
    const tooltip = this.viewContainerRef.createComponent(WidgetGraphTooltipComponent);
    tooltip.setInput('header', header);
    tooltip.setInput('rows', rows);
    tooltip.setInput('footer', footer);
    tooltip.changeDetectorRef.detectChanges();

    // Get the sanitized HTML and remove the tooltip component
    const html = tooltip.location.nativeElement.innerHTML.replaceAll('"', `'`);
    const sanitized = DOMPurify.sanitize(html);
    this.viewContainerRef.clear();
    return sanitized;
  }

  /**
   * Handles all errors regarding loading data into this widget
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorHandler(err: any) {
    this.isLoading$.next(false);
    this.hasData.set(false);
    this.hasError.set(true);
    this.errorDetails.set(undefined);
    const message = errorToString(err)
      .replace(/<[^>]*>/gi, ' ')
      .replace(/\s+/gi, ' ')
      .trim()
      .replace('Server Error Server Error.', 'Server Error: ');
    switch (err.status) {
      case 401:
      case 403:
        this.error.set('You are not authorized to view this data');
        break;
      case 404:
        this.error.set('The data you are looking for does not exist');
        this.errorDetails.set(message);
        break;
      case 499:
        this.error.set('An unknown server error occurred. Please notify Noova support');
        this.errorDetails.set(message);
        break;
      case 500:
        this.error.set('An internal server error occurred. Please notify Noova support');
        this.errorDetails.set(message);
        break;
      case 504:
        this.error.set('The server took too long to respond. Please try again later.');
        break;
      default:
        this.error.set(err.statusText + ': ' + message);
    }
  }

  /**
   * Generic function to force highcharts to redraw after any layout changes has been made
   */
  async onResize() {
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) chart.reflow();
  }

  getSeries(id: string): Highcharts.Series {
    return this.chartObj()?.get(id) as Highcharts.Series;
  }

  /**
   * Called when language changes.
   * Please try to do chart changes in the highcharts config before you resort to implement this method
   */
  onLangChange(lng: LangChangeEvent, chart?: Highcharts.Chart) {
    this.currentLanguage.set(lng.lang);
    if (chart && this.hasData()) {
      // The global highcharts language object is already updated
      chart.redraw(false);
    }
  }

  tabChanged($event: MatTabChangeEvent) {
    this.backTitle = $event.tab.textLabel;
    this.currentTabIndex = $event.index;
  }

  /**
   * Invoked when hovering over graph. Will ask the graphPredicate to identify the tablerow
   * belonging to this data point, and highlight it
   * @returns
   */
  graphMouseOver() {
    const self = this;
    return function () {
      const item = self.tableData().find((i: T) =>
        self.graphPredicate({
          row: i,
          x: this.x,
          y: this.y,
          point: this,
          seriesName: this.series.name,
          series: this.series,
        }),
      );
      if (item) {
        item._active = true;
        document.querySelector(`[row-id="${self.createHash(item)}"]`)?.classList.add('ag-row-hover');
      }
    };
  }

  /**
   * Invoked when hovering graph ends
   * @returns
   */
  graphMouseOut() {
    const self = this;
    return function () {
      const item = self.tableData().find((i: T) =>
        self.graphPredicate({
          row: i,
          x: this.x,
          y: this.y,
          point: this,
          seriesName: this.series.name,
          series: this.series,
        }),
      );
      if (item) {
        document.querySelector(`[row-id="${self.createHash(item)}"]`)?.classList.remove('ag-row-hover');
      }
    };
  }

  /**
   * Invoked when hovering a table row. Will ask the graphPredicate to identify the graph point
   * belonging to this row, and show tooltip for it.
   * @param row
   */
  async tableHover(row: T) {
    if (!row) return;
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart && chart.tooltip.options.enabled) {
      const points = chart.series
        .reduce(
          (acc, s) => [
            ...acc,
            ...(s.points?.filter((p: Highcharts.Point | undefined) => {
              const series = p?.series || s;
              return (
                p != null && this.graphPredicate({ row, x: p.x, y: p.y, point: p, seriesName: series.name, series })
              );
            }) ?? []),
          ],
          [] as Highcharts.Point[],
        )
        ?.filter((p: Highcharts.Point | undefined) => p != null && p.y != null);
      if (points?.length) {
        points.forEach((p: Highcharts.Point | undefined) => p?.setState('hover'));
        this.isTooltipShared = chart.tooltip.options.shared;
        chart.tooltip.update({ shared: true });
        chart.tooltip.refresh(points);
      }
    }
  }

  async reset(row: T) {
    if (!row) return;
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) {
      const points = chart.series
        .map((s) =>
          s.points?.find((p) =>
            this.graphPredicate({ row, x: p.x, y: p.y, point: p, seriesName: p.series?.name, series: p.series }),
          ),
        )
        .filter((p) => p != null && p.y != null);
      if (points?.length) {
        points.forEach((p: Highcharts.Point | undefined) => p?.setState());
      }
      chart.tooltip.update({ shared: this.isTooltipShared });
      chart.tooltip.hide();
    }
  }

  pointItemClick(): Highcharts.PointClickCallbackFunction {
    const self = this;
    return function (event: Highcharts.PointClickEventObject) {
      return self.toggleSeriesFocus.call(self, this.series.chart, this.series, event);
    };
  }

  legendItemClick(): Highcharts.SeriesLegendItemClickCallbackFunction {
    const self = this;
    return function (event: Highcharts.SeriesLegendItemClickEventObject) {
      return self.toggleSeriesFocus.call(self, this.chart, this, event);
    };
  }

  private toggleSeriesFocus(
    chart: Highcharts.Chart,
    series: Highcharts.Series,
    event: Highcharts.SeriesLegendItemClickEventObject | Highcharts.PointClickEventObject,
  ) {
    const shiftDown = 'browserEvent' in event ? event.browserEvent.shiftKey : this.shiftDown;
    if (shiftDown) {
      event.preventDefault();
      if (this.chartState.state.length > 0) {
        // Restore previous state
        chart.series.forEach((s) => {
          const state = this.chartState.state.find((c) => c.id === (s.userOptions.id || s.name));
          if (state) s.setVisible(state.previous, false);
        });
      }
      if (this.chartState.selected !== (series.userOptions.id || series.name)) {
        this.chartState.selected = series.userOptions.id || series.name;
        // Save current visibility state of all series
        this.chartState.state = chart.series.map((s) => {
          const id = s.userOptions.id || s.name;
          return {
            id,
            previous: id === this.chartState.selected ? !s.visible : s.visible,
            current: s === series,
          };
        });
        // Hide all others
        this.chartState.state.forEach((s) => this.getSeries(s.id).setVisible(s.current, false));
      } else {
        this.chartState.selected = undefined;
        this.chartState.state = [];
      }
      chart.redraw(true);
      return false;
    } else {
      this.chartState.selected = undefined;
      this.chartState.state = [];
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  settingsClosed() {}

  /** Create a short hash of any object. Used to identify a row */
  protected createHash(obj: any) {
    const { _active, ...rest } = obj;
    const string = JSON.stringify(rest);
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      const code = string.charCodeAt(i);
      hash = (hash << 5) - hash + code;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  configSaveTimeout: any = null;
  saveConfig(name: string, value: any) {
    if (!(name in this.config())) return; // Only known properties can be saved

    if (this.configQueue == null) this.configQueue = structuredClone(this.config());
    if (this.configQueue != null && Object.keys(this.configQueue).length > 0 && this.configQueue![name] !== value) {
      this.configQueue = { ...this.configQueue, [name]: value };
      // Debounce! Wait until the queue has not received any more changes for 200ms
      if (this.configSaveTimeout) clearTimeout(this.configSaveTimeout);
      this.configSaveTimeout = setTimeout(async () => {
        if (this.configQueue != null) {
          await firstValueFrom(this.settings.save({ [this.widgetName]: this.configQueue }, this.node));
          this.configQueue = null;
          this.configSaveTimeout = null;
        }
      }, 200);
    }
  }

  /**
   * Toggles visibility of a series in the graph and the corresponding column in the table
   *
   * @param flag The visibility state
   * @param seriesId The id of the series
   * @param gridColId The id of the column in the table
   */
  setVisible(flag: boolean, seriesId = '', gridColId = seriesId) {
    // Toggle series
    if (this.chartObj() && seriesId != '') {
      const series = this.chartObj()!.get(seriesId) as Highcharts.Series;
      if (series && series!.visible !== flag) {
        series.setVisible(flag);
      }
      // Set or remove from hidden series
      this.hiddenSeries.set(
        this.chartObj()
          ?.series.filter((c) => c.visible != true)
          .map((c) => c.userOptions.id || c.name) || [],
      );
    }
    // Toggle column
    if (this.gridApi()) {
      this.gridApi()!.applyColumnState({ state: [{ colId: gridColId, hide: !flag }] });
    }
  }

  /**
   * Creates a highcharts series hide callback
   *
   * In order for this to work, the series id must equal the column id in the table
   */
  hideData(): Highcharts.SeriesHideCallbackFunction {
    const self = this;
    return function () {
      self.setVisible.call(self, false, this.userOptions.id);
      self.saveConfig.call(self, this.userOptions.id!, false);
    };
  }

  /**
   * Creates a highcharts series hide callback
   *
   * In order for this to work, the series id must equal the column id in the table
   */
  showData(): Highcharts.SeriesShowCallbackFunction {
    const self = this;
    return function () {
      self.setVisible(true, this.userOptions.id);
      self.saveConfig(this.userOptions.id!, true);
    };
  }

  // FIX PRINT LAYOUT ---------------------------------
  /**
   * Scale the graphs to fit an A4 page before print is called. This makes
   * sure the graph does not bleed out of the page. Highcharts seems persistant
   * on setting absolute widths for all things graph related.
   */

  @HostListener('window:beforeprint')
  async _beforePrint() {
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (chart as { [key: string]: any })['oldSize'] = [chart.chartWidth, chart.chartHeight];
      chart.setSize(this.isFullscreen() ? 760 : 380, 270, false);
      chart.reflow();
    }
  }

  /**
   * Scale back the graph to it's original size after print is done.
   */
  @HostListener('window:afterprint')
  async _afterPrint() {
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [width, height] = (chart as { [key: string]: any })['oldSize'];
      chart.setSize(width, height, false);
      chart.reflow();
    }
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  onKey(event: KeyboardEvent) {
    this.shiftDown = event.shiftKey;
  }
}
