<!-- Templates for reuse in the widget or fullscreen -->
<!-- ref: https://github.com/angular/angular/issues/22972 -->
<ng-template #head
  ><ng-content select="[app-widget-header]">
    <span>{{ header() | translate }}</span>
    @if (subtitle()) {
      <span> - {{ subtitle() | translate }}</span>
    }
    @if (subheader()) {
      <span class="sub-header"> - {{ subheader() | translate }}</span>
    }
  </ng-content></ng-template
>
<ng-template #extra><ng-content select="[app-widget-extra]"></ng-content></ng-template>
<ng-template #content>
  <ng-content>
    <app-warn>This widget has no content</app-warn>
  </ng-content>
</ng-template>

<!-- The settings, available as popup dialog in fullscreen, and flip-over dialog in widget view -->
<ng-template #settings>
  <ng-content select="[app-widget-settings]"> </ng-content>
</ng-template>

<!-- Widget view -->
@if (canDisplay$ | async; as canDisplay) {
  @if (!fullscreen) {
    <!-- Front face -->
    <div class="front">
      <header>
        @if (!isReadOnly) {
          <div class="drag-handler drag-handler-icon"><mat-icon>drag_indicator</mat-icon></div>
        } @else {
          <div></div>
        }
        <h1 [title]="header() | translate">
          <ng-container *ngTemplateOutlet="head"></ng-container>
        </h1>
        @if (!isReadOnly) {
          <ng-container *ngTemplateOutlet="extra"></ng-container>
          <app-icon-beta></app-icon-beta>
          <a
            mat-icon-button
            (click)="select()"
            [routerLink]="route()"
            [queryParams]="queryParams()"
            title="{{ 'View details' | translate }}">
            <mat-icon>open_in_new</mat-icon>
          </a>
          <button mat-icon-button type="button" (click)="toggleSettings($event)" title="{{ 'Show more' | translate }}">
            <mat-icon>more_vert</mat-icon>
          </button>
        }
      </header>

      @if (wrapper().isLoading$ | async) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }

      <main>
        @if (!wrapper().hasError()) {
          <ng-container *ngTemplateOutlet="content"></ng-container>
        } @else {
          <app-warn type="report">
            @if (wrapper().errorDetails() != null) {
              <div>{{ wrapper().error() || '' | translate }}</div>
              <code class="details">{{ wrapper().errorDetails() }}</code>
            } @else {
              {{ wrapper().error() || '' | translate }}
            }
          </app-warn>
        }
      </main>
    </div>

    <!-- Back face (Settings) -->
    <div class="back">
      <header>
        <span></span>
        <h1>
          @if (header()) {
            <span>{{ header() | translate }}</span>
            @if (backHeader()) {
              - <span>{{ backHeader() | translate }}</span>
            }
          }
        </h1>
        <app-icon-beta></app-icon-beta>
        <button
          mat-icon-button
          type="button"
          (click)="remove()"
          title="{{ 'Remove widget from dashboard' | translate }}">
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-icon-button
          type="button"
          (click)="toggleSettings($event)"
          title="{{ 'Close settings' | translate }}">
          <mat-icon>close</mat-icon>
        </button>
      </header>

      <main>
        @if (showSettings == true) {
          <ng-container *ngTemplateOutlet="settings"></ng-container>
        }
      </main>
    </div>
  } @else {
    <!-- Fullscreen view -->
    <header>
      <a mat-icon-button [routerLink]="['..']" queryParamsHandling="preserve" class="back-button">
        <mat-icon>chevron_left</mat-icon>
      </a>
      <h1 #customHeadFull>
        <ng-container *ngTemplateOutlet="head"></ng-container>
      </h1>
      <app-icon-beta></app-icon-beta>
      <ng-container *ngTemplateOutlet="extra"></ng-container>
      @if (wrapper().chartObj() != null) {
        <mat-button-toggle-group
          [value]="isGraphFocused() || isGridFocused() ? 'split' : ''"
          hideSingleSelectionIndicator="true">
          @if (!isGraphFocused()) {
            <mat-button-toggle value="graph" (click)="focusGraph()" title="{{ 'Focus on graph' | translate }}">
              <mat-icon>query_stats</mat-icon>
            </mat-button-toggle>
          }
          @if (isGraphFocused() || isGridFocused()) {
            <mat-button-toggle value="split" (click)="normalizeView()" title="{{ 'Normalize view' | translate }}">
              <mat-icon>zoom_in_map</mat-icon>
            </mat-button-toggle>
          }
          @if (!isGridFocused()) {
            <mat-button-toggle value="grid" (click)="focusGrid()" title="{{ 'Focus on grid' | translate }}">
              <mat-icon>grid_on</mat-icon>
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
      <button mat-icon-button type="button" (click)="toggleSettings($event)" title="{{ 'Settings' | translate }}">
        <mat-icon>more_vert</mat-icon>
      </button>
    </header>

    @if (wrapper().isLoading$ | async) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }

    <main>
      @if (!wrapper().hasError()) {
        <ng-container *ngTemplateOutlet="content"></ng-container>
      } @else {
        <app-warn type="report">
          @if (wrapper().errorDetails() != null) {
            <div>{{ wrapper().error() || '' | translate }}</div>
            <code class="details">{{ wrapper().errorDetails() }}</code>
          } @else {
            {{ wrapper().error() || '' | translate }}
          }
        </app-warn>
      }
    </main>
  }
} @else {
  <app-warn type="report">
    @if (wrapper().errorDetails() != null) {
      <div>
        {{ wrapper().widgetName | translate }} {{ 'is not available for' | translate }}
        {{ treeNode().type | translate }}
      </div>
      <code class="details">{{ wrapper().errorDetails() }}</code>
    } @else {
      {{ wrapper().widgetName | translate }} {{ 'is not available for' | translate }}
      {{ treeNode().type | translate }}
    }
  </app-warn>
}
