import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, timer } from 'rxjs';
import { first, map, retryWhen, switchMap, tap } from 'rxjs/operators';

import { AppService } from '@logic-suite/shared/app.service';
import { RequestCache } from '@logic-suite/shared/cache';
import { compareObjects } from '@logic-suite/shared/utils';

import { CustomerService } from '../../access';
import { WidgetConfig } from './widget-config';

/**
 *
 */
@Injectable({ providedIn: 'root' })
export class WidgetService {
  private readonly http = inject(HttpClient);
  private readonly customer = inject(CustomerService);
  private readonly cache = inject(RequestCache);
  private readonly app = inject(AppService);

  /** Holds the current dashboard of widget config */
  private _active$: BehaviorSubject<WidgetConfig[]> = new BehaviorSubject([] as WidgetConfig[]);
  active$ = this._active$.asObservable();

  /** Flag holding true when widget area is loading widgets */
  isLoading$ = new BehaviorSubject(false);

  getConfigFrom(container: WidgetConfig[]) {
    return container.map(({ componentRef, ...config }) => config);
  }

  setActive(widgets: WidgetConfig[]) {
    const oldVal = this.getConfigFrom(this.getCurrentlyActive());
    const newVal = this.getConfigFrom(widgets).map((w) => {
      const old = oldVal.find((o) => o.componentName === w.componentName);
      w.widgetID = old?.widgetID || w.widgetID;
      w.cols = w?.cols || old?.cols || 1;
      w.rows = w?.rows || old?.rows || 1;
      w.x = w?.x || old?.x || 0;
      w.y = w?.y || old?.y || 0;
      return w;
    });
    if (!compareObjects(oldVal, newVal)) {
      this._active$.next(newVal);
    }
  }

  getCurrentlyActive(): WidgetConfig[] {
    return this._active$.value;
  }

  /**
   * Get available widgets for å given part of the asset tree
   */
  getAvailable(type?: string): Observable<WidgetConfig[]> {
    return this.customer.selectedCustomer$.pipe(
      first(),
      switchMap((customer) =>
        this.http.get<string[]>(`/api/shared/Widget`, {
          params: {
            // Mandatory parameter
            applicationID: '' + this.app.getApplicationID(),
            CustomerID: '' + customer.customerID,
            ...(type && { type }),
          },
        }),
      ),
      map((res) => (res?.length ? res.map((c) => ({ componentName: c }) as WidgetConfig) : [])),
    );
  }

  /**
   * Get the dashboard config for the currently active selection.
   */
  getActive(type?: string, id?: string): Observable<WidgetConfig[]> {
    let retries = 0;
    return this.customer.selectedCustomer$.pipe(
      first(),
      switchMap((customer) =>
        this.http.get<WidgetConfig[]>(`/api/shared/Widget/Layout`, {
          params: {
            // Mandatory parameter
            applicationID: '' + this.app.getApplicationID(),
            CustomerID: '' + customer.customerID,
            // Optional parameters
            ...(type && { type }),
            ...(id && { id }),
          },
        }),
      ),
      retryWhen((errors) => {
        retries++;
        if (retries > 5) return throwError(errors); // Too many retries. Give up
        console.log('Failed to load widget layout. Retrying...', retries);
        return timer(retries * 1000); // Retry after 1s, 2s, etc...
      }),
      tap((widgets) => this.setActive(widgets)),
    );
  }

  /**
   * Save dashboard config for the currently active selection
   */
  saveActive(config: WidgetConfig[], type?: string, id?: string): Observable<WidgetConfig[]> {
    return this.customer.selectedCustomer$.pipe(
      first(),
      switchMap((customer) =>
        this.http.post<WidgetConfig[]>(`/api/shared/Widget/Layout`, config, {
          params: {
            // Mandatory parameter
            applicationID: '' + this.app.getApplicationID(),
            CustomerID: '' + customer.customerID,
            // Optional parameters
            ...(type && { type }),
            ...(id && { id }),
          },
        }),
      ),
      tap((widgets) => {
        // Invalidate cache for the active dashboard
        this.cache.invalidate(`/api/shared/Widget/Layout`);
      }),
    );
  }

  reset(type?: string): Observable<WidgetConfig[]> {
    return this.customer.selectedCustomer$.pipe(
      first(),
      switchMap((customer) =>
        this.http.post<WidgetConfig[]>('/api/shared/Widget/Layout/Reset', null, {
          params: {
            // Mandatory parameter
            applicationID: '' + this.app.getApplicationID(),
            CustomerID: '' + customer.customerID,
            // Optional parameters
            ...(type && { type }),
          },
        }),
      ),
      tap((widgets) => {
        // Invalidate cache for the active dashboard
        this.cache.invalidate(`/api/shared/Widget/Layout`);
        this.setActive(widgets);
      }),
    );
  }
}
