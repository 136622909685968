import { Component, computed, input } from '@angular/core';
import { NumericPipe } from '@logic-suite/shared/pipes';
import { numberFormat } from '@logic-suite/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipRow } from './tooltip-row';

@Component({
  selector: 'app-widget-graph-tooltip',
  standalone: true,
  imports: [TranslateModule, NumericPipe],
  template: `
    <table>
      @if (hasHeader()) {
        <thead>
          <tr class="header">
            <th [attr.colspan]="headerColSpan()">{{ headerRow().name | translate }}</th>
            @if (headerRow().value) {
              <th>{{ getValue(headerRow().value, headerRow()) }}</th>
            }
            @if (headerRow().extra) {
              <th>{{ getValue(headerRow().extra!, headerRow()) }}</th>
            }
          </tr>
        </thead>
      }
      <tbody>
        @for (row of rows(); track row) {
          @if (row.type === 'line') {
            <tr>
              <td [attr.colspan]="rowColumnSize()" class="line"></td>
            </tr>
          } @else {
            <tr>
              @if (row.name) {
                <th>{{ row.name | translate }}</th>
                @if (row.color) {
                  <td><span class="bullet" [style]="'color: ' + row.color">●</span></td>
                }
              }
              <td class="number">{{ getValue(row.value, row) }}</td>
              @if (row.extra) {
                @if (row.extraPrefix) {
                  <th>/ Acc</th>
                }
                <td class="number">{{ getValue(row.extra, row) }}</td>
              }
            </tr>
          }
        } @empty {
          @if (!hasHeader() && !hasFooter()) {
            <tr>
              <td [attr.colspan]="rowColumnSize()" class="empty">No data</td>
            </tr>
          }
        }
      </tbody>
      @if (hasFooter()) {
        <tbody>
          <tr class="footer">
            <th [attr.colspan]="footerColSpan()">{{ footerRow().name | translate }}</th>
            @if (footerRow().value) {
              <th>{{ getValue(footerRow().value, footerRow()) }}</th>
            }
            @if (footerRow().extra) {
              <th>{{ getValue(footerRow().extra!, footerRow()) }}</th>
            }
          </tr>
        </tbody>
      }
    </table>
  `,
  styles: ``,
})
export class WidgetGraphTooltipComponent {
  header = input<TooltipRow | string>('');
  footer = input<TooltipRow | string>('');
  rows = input<TooltipRow[]>([] as any[]);

  headerRow = computed(() => {
    return Object.assign(
      {
        decimals: 2,
        unit: '',
      },
      typeof this.header() === 'string'
        ? ({ name: this.header() as string } as TooltipRow)
        : (this.header() as TooltipRow),
    );
  });
  headerColSpan = computed(() => {
    const row = this.headerRow();
    return (
      1 + this.rowColumnSize() - [row.name, row.value, row.color, row.extraPrefix, row.extra].filter((v) => !!v).length
    );
  });
  hasHeader = computed(() => !!this.header());

  footerRow = computed(() => {
    return Object.assign(
      {
        decimals: 2,
        unit: '',
      },
      typeof this.footer() === 'string'
        ? ({ name: this.footer() as string } as TooltipRow)
        : (this.footer() as TooltipRow),
    );
  });
  footerColSpan = computed(() => {
    const row = this.footerRow();
    return (
      1 + this.rowColumnSize() - [row.name, row.value, row.color, row.extraPrefix, row.extra].filter((v) => !!v).length
    );
  });
  hasFooter = computed(() => !!this.footer());

  rowColumnSize = computed(() =>
    Math.max(
      ...this.rows().reduce((acc, row) => {
        return acc.add([row.name, row.value, row.color, row.extraPrefix, row.extra].filter((v) => !!v).length);
      }, new Set<number>()),
    ),
  );

  getValue(value: string | number, row: TooltipRow) {
    if (isNaN(+value)) {
      return value;
    }
    return `${numberFormat(+value, 'decimal', row.decimals || 2)}${row.unit ? ` [${row.unit}]` : ''}`;
  }
}
