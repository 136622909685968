import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@logic-suite/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from '../../chart/chart.module';
import { WarnComponent } from '../../feedback';
import { IconBetaComponent } from './beta.component';
import { WidgetContainerDirective } from './widget-container.directive';
import { WidgetLinkComponent } from './widget-link.component';
import { WidgetComponent } from './widget.component';

@NgModule({
  declarations: [IconBetaComponent, WidgetComponent, WidgetContainerDirective, WidgetLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    DirectivesModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    WarnComponent,
    ChartsModule,
  ],
  exports: [WidgetComponent, WidgetContainerDirective, WidgetLinkComponent],
})
export class WidgetModule {}
